// MAŁE TELEFONY

@media screen and (max-width: 359px) {
  .showcase {
    h1 {
      font-size: 3.9rem;
    }
  }

  .prices {
    &__pin {
      width: 16.5em;
      height: 16.5em;
      &-img {
        width: 3em;
      }

      &-title {
        font-size: 2.5rem;
      }

      &-value {
        font-size: 3.2rem;
      }

      &-description {
        font-size: 1.4rem;
      }
    }
  }

  .contact {
    &__phones {
      font-size: 1.6rem;
    }
  }
}

@media screen and (max-width: 300px) {
  .showcase {
    h1 {
      font-size: 3.2rem;
    }
  }

  .about,
  .tools,
  .offer,
  .prices {
    &__img img {
      width: 95%;
    }
  }

  .offer {
    &__cards {
      width: 95%;

      .card {
        width: 16em;
        height: 21em;

        &__img {
          font-size: 5rem;
        }

        &__title {
          font-size: 2.8rem;
        }

        &__list {
          font-size: 1.4rem;
        }
      }
    }
  }

  .prices {
    &__pin {
      width: 14.5em;
      height: 14.5em;
    }
  }

  .contact {
    height: 1000px;
    &__address {
      &-name {
        font-size: 2rem;
      }
    }

    &__phones {
      display: grid;
      grid-template-columns: 1fr 3fr;
      grid-template-rows: 1fr 1fr;
      &-text {
        grid-column: 1 / 2;
        grid-row: 1 / 3;
      }
      &-first {
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        font-size: 1.8rem;
      }
      &-second {
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        font-size: 1.8rem;
      }
    }
  }
}

@media screen and (max-height: 500px) {
  .menu {
    &__list {
      margin-top: 3.5em;
    }

    &__item {
      margin: 1.2em auto;

      a {
        font-size: 2.2rem;
      }
    }
  }
}

@media screen and (max-height: 321px) {
  .menu {
    &__list {
      margin-top: 2em;
    }
    &__item {
      margin: 0.5em auto;

      a {
        font-size: 2rem;
      }
    }
  }
}

@media screen and (max-height: 280px) {
  .menu {
    &__list {
      margin-top: 3em;
    }
    &__item {
      margin: 0.4em auto;

      a {
        font-size: 1.6rem;
      }
    }
  }
}

// WIDOKI POZIOME

@media screen and (orientation: landscape) {
  .showcase {
    &__logo {
      font-size: 2rem;
    }

    h1 {
      font-size: 3rem;
      max-width: 100%;
    }

    p {
      font-size: 1.8rem;
      max-width: 100%;
    }
  }

  .about,
  .tools,
  .offer,
  .prices {
    &__img img {
      width: 15em;
    }

    &__text {
      font-size: 1.5rem;
    }
  }

  .offer {
    h3 {
      font-size: 1.6rem;
    }
  }

  .prices {
    &__pins {
      flex-direction: row;
    }
  }
}

@media screen and (min-width: 640px) and (orientation: landscape) {
  .showcase {
    &__logo {
      font-size: 2.5rem;
    }

    h1 {
      font-size: 3.5rem;
    }

    p {
      font-size: 1.8rem;
    }
  }

  .about,
  .tools,
  .offer,
  .prices {
    &__img img {
      width: 18em;
    }

    &__text {
      font-size: 1.5rem;
    }
  }

  .offer {
    h3 {
      font-size: 1.6rem;
    }
    &__cards {
      flex-direction: row;
    }
  }

  .contact {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(6, 1fr);
    height: 800px;

    &__title {
      grid-column: 1 / 3;
    }

    &__address {
      grid-row: 2 / 5;
      margin: 0;
    }

    &__email {
      grid-column: 2 / 3;
      grid-row: 2 / 3;
      margin: 0;
    }

    &__phones {
      grid-column: 2 / 3;
      grid-row: 3 / 4;
      margin: 0;
    }

    &__identity {
      grid-column: 2 / 3;
      grid-row: 4 / 5;
      padding-bottom: 0;
    }

    &__open {
      grid-column: 1 / 3;
      grid-row: 5 / 6;
      padding: 3em 0;
    }

    &__map {
      grid-column: 1 / 3;
      grid-row: 6 / 7;
    }
  }
}

@media screen and (min-width: 811px) and (orientation: landscape) {
  .showcase {
    h1 {
      font-size: 4rem;
    }

    p {
      font-size: 2rem;
    }
  }

  .about,
  .tools,
  .offer,
  .prices {
    &__title {
      font-size: 3.5rem;
      padding: 2em 1em 2em;
    }
    &__img img {
      width: 20em;
    }

    &__text {
      font-size: 1.8rem;
    }
  }

  .offer {
    h3 {
      font-size: 1.6rem;
    }
    &__cards {
      flex-direction: row;
    }
  }
}

@media screen and (min-width: 960px) and (orientation: landscape) {
  .showcase {
    &__logo {
      font-size: 4rem;
    }

    h1 {
      font-size: 8rem;
      line-height: 10rem;
      max-width: 80%;
    }

    p {
      font-size: 4rem;
      max-width: 100%;
    }

    a {
      font-size: 3rem;
    }
  }

  .hamburger {
    &-box {
      width: 60px;
      height: 40px;
      display: inline-block;
      position: relative;
    }
    &-inner,
    &-inner::before,
    &-inner::after {
      width: 60px;
      height: 6px;
    }
    &-inner::before {
      top: -38px;
    }
  }

  .about,
  .tools,
  .offer,
  .prices {
    &__title {
      font-size: 4.5rem;
    }
    &__img img {
      width: 30em;
    }

    &__text {
      font-size: 2.2rem;
    }
  }

  .offer {
    h3 {
      font-size: 2.2rem;
    }
  }

  .tools {
    &__text {
      margin: 1em 1em 2.2em;
    }
  }

  .contact {
    font-size: 2.2rem;
    height: 900px;

    &__title {
      margin-bottom: 1em;
      font-size: 4.5rem;
    }

    &__address {
      &-name {
        font-size: 3rem;
      }
    }

    &__email {
      margin: 0;

      .fas {
        margin-right: 1em;
      }
    }

    &__phones {
      margin: 0;

      & span {
        margin-right: 0.5em;
      }
    }

    &__identity {
      padding-bottom: 0;

      & span {
        margin-right: 0.5em;
      }
    }

    &__open {
      padding-bottom: 1em;
    }

    &__map {
      height: 400px;
    }
  }
}

@media screen and (min-width: 1330px) and (orientation: landscape) {
  .contact {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(5, 1fr);
    height: 500px;

    &__title {
      grid-column: 1 / 3;
    }

    &__address {
      grid-column: 1 / 2;
      grid-row: 2 / 5;
      margin: 0;
    }

    &__email {
      grid-column: 2 / 3;
      grid-row: 2 / 3;
      margin: 0;
    }

    &__phones {
      grid-column: 2 / 3;
      grid-row: 3 / 4;
      margin: 0;
    }

    &__identity {
      grid-column: 2 / 3;
      grid-row: 4 / 5;
      padding-bottom: 0;
    }

    &__open {
      grid-column: 1 / 4;
      grid-row: 5 / 6;
      padding: 3em 0;
    }

    &__map {
      grid-column: 3 / 4;
      grid-row: 1 / 6;
      height: 250px;
    }
  }
}

// WIDOKI PIONOWE

@media screen and (min-width: 736px) and (orientation: portrait) {
  .showcase {
    h1 {
      font-size: 7rem;
      line-height: 9rem;
      max-width: 80%;
    }

    p {
      font-size: 3rem;
      line-height: 6rem;
      max-width: 100%;
    }

    a {
      font-size: 2.5rem;
    }
  }

  .about,
  .tools,
  .offer,
  .prices {
    &__title {
      font-size: 4rem;
      padding: 2em 1em 2em;
    }
    &__img img {
      width: 20em;
    }

    &__text {
      font-size: 2.2rem;
    }
  }

  .offer {
    h3 {
      font-size: 2.2rem;
    }
    &__cards {
      flex-direction: row;
    }
  }

  .prices {
    &__pins {
      flex-direction: row;
    }
  }

  .contact {
    font-size: 2.4rem;
    height: 1100px;

    &__title {
      font-size: 4rem;
    }

    &__address {
      &-name {
        font-size: 3rem;
      }
    }

    &__phones {
      font-size: 2.4rem;
    }

    &__open {
      &-text {
        font-size: 2.2rem;
      }
    }

    &__map {
      height: 400px;
      width: 95%;
    }
  }
}

@media screen and (min-width: 1024px) and (orientation: portrait) {
  .showcase {
    h1 {
      font-size: 9rem;
      line-height: 12rem;
    }

    p {
      font-size: 4rem;
      line-height: 6rem;
    }

    a {
      font-size: 3rem;
    }
  }
  .about,
  .tools,
  .offer,
  .prices {
    &__title {
      font-size: 5rem;
    }
    &__img img {
      width: 30em;
    }

    &__text {
      font-size: 3rem;
    }
  }

  .offer {
    h3 {
      font-size: 3rem;
    }
  }

  .contact {
    font-size: 3rem;
    height: 1400px;

    &__title {
      font-size: 4.5rem;
    }

    &__address {
      &-name {
        font-size: 4rem;
      }
    }

    &__phones {
      font-size: 3rem;
    }

    &__open {
      &-text {
        font-size: 2.8rem;
      }
    }

    &__map {
      height: 500px;
    }
  }
}
