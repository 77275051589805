@mixin reset {
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    font-size: 62.5%;
    scroll-behavior: smooth;
  }
}

@mixin right-top-absolute($height: 100%) {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: $height;
}

@mixin right-top-fixed($height: 100%) {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: $height;
}

@mixin right-top-relative($height: 100%) {
  position: relative;
  top: 0;
  right: 0;
  width: 100%;
  height: $height;
}
