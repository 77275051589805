@use "colors" as c;
@use "mixins" as m;
@use "variables" as v;
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");

@include m.reset;

body {
  font-family: "Poppins", sans-serif;
  font-size: 1.6rem;
  width: 100%;
  background-color: c.$gray;
  // overflow: hidden;
}

.menu {
  position: fixed;
  top: 0;
  right: 0;
  width: 0;
  height: 100%;
  display: flex;
  align-items: center;
  transition: width 0.3s;

  &__list {
    list-style: none;
  }

  &__item {
    margin: 1.8em auto;

    a {
      margin-left: 2em;
      text-decoration: none;
      font-size: 2.4rem;
      color: c.$black;
      opacity: 0;
      transition: opacity 0.1s;
    }

    a:hover {
      color: c.$orange;
    }
  }

  &.is-active {
    & {
      transition-delay: 0.1s;
      width: v.$menu-width;
    }
    a {
      opacity: 1;
    }
  }
}

.container {
  @include m.right-top-absolute(auto);

  &.is-active {
    right: v.$menu-width;

    .showcase {
      &__toggle {
        .hamburger-inner,
        .hamburger-inner::before,
        .hamburger-inner::after {
          background-color: c.$black;
        }
      }
    }
  }
}

.showcase {
  @include m.right-top-relative(100vh);
  padding: 1em 1.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: c.$black;
  color: c.$white;
  z-index: 2;

  &__header {
    @include m.right-top-fixed(auto);
    padding: 0.2em 1.5em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;

    &.out-home {
      background: rgba($color: c.$black, $alpha: 0.5);
    }
  }

  &__logo {
    color: c.$orange;
    font-size: 2.5rem;
  }

  &__toggle {
    position: relative;

    .hamburger {
      .hamburger-inner,
      .hamburger-inner::before,
      .hamburger-inner::after {
        background-color: c.$orange;
        transition: background-color 0.3s;
      }
    }
  }

  &__video {
    @include m.right-top-absolute;
    object-fit: cover;
    opacity: 0.8;
  }

  &__overlay {
    @include m.right-top-absolute;
    background: rgba($color: c.$black, $alpha: 0.7);
  }

  &__text {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 20vh;
    height: 65vh;
    z-index: 10;

    h1 {
      font-size: 4.5rem;
      font-weight: 800;
      line-height: 5rem;
    }

    p {
      display: inline-block;
      max-width: 50%;
      font-size: 2rem;
      font-weight: 400;
      line-height: 3rem;
    }

    a {
      display: inline-block;
      padding: 0.5em 1em;
      font-weight: 200;
      letter-spacing: 1px;
      text-decoration: none;
      text-align: center;
      border: 1px solid c.$orange;
      border-radius: 5px;
      background-color: transparent;
      color: c.$white;
      transition: background-color 0.3s, color 0.3s;

      &:hover {
        //jakaś animacja
        background-color: c.$black;
        color: c.$orange;
      }
    }
  }
}

.wrapper {
  max-width: 1300px;
  margin: 0 auto;
}

.about,
.tools,
.offer,
.prices {
  @include m.right-top-relative(auto);

  &__title {
    font-size: 4rem;
    padding: 3em 1em 1em;
    text-transform: uppercase;
    text-align: center;
  }

  &__img img {
    margin: 0 0.5em 0 0.5em;
    width: 10em;
    float: left;
  }

  &__text {
    text-align: justify;
    margin: 0 0.5em;
    font-size: 1.8rem;
  }
}

.tools {
  &__img img {
    float: right;
  }
}

.offer {
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h3 {
    padding-bottom: 4em;
    font-size: 2rem;
  }

  &__cards {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;

    .card {
      background-color: transparent;
      width: 18em;
      height: 23em;
      perspective: 1000px;
      margin-bottom: 3em;

      &__inner {
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        transition: transform 0.8s;
        transform-style: preserve-3d;
      }

      &:hover .card__inner {
        transform: rotateY(180deg);
      }

      &__front,
      &__back {
        position: absolute;
        width: 100%;
        height: 100%;
        backface-visibility: hidden;

        border: 2px solid c.$orange;
        border-radius: 1em;

        background-color: c.$gray;
        color: c.$black;
      }

      &__back {
        transform: rotateY(180deg);
      }

      &__img {
        padding: 0.1em;
        font-size: 7rem;
        color: c.$orange;
      }

      &__title {
        text-transform: uppercase;
        font-size: 3.2rem;
        color: c.$dark;
        padding-bottom: 0.8em;
      }

      &__list {
        list-style: none;
        padding: 0 1em 1em;
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 2;
        text-transform: uppercase;
      }

      &__verte {
        font-weight: 200;
      }
    }
  }
}

.prices {
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1em;

  &__descriptions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2em;
  }

  &__text {
    margin-bottom: 1em;
  }
  &__pins {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    width: 90%;
    margin-bottom: 2em;
  }

  &__pin {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 18em;
    height: 18em;
    margin-bottom: 2em;
    border: 1px solid c.$orange;
    box-shadow: -5px 5px 10px #555;

    &-img {
      width: 3.5em;
    }

    &-title {
      padding: 0 0.5rem;
      font-size: 2.6rem;
      text-align: center;
      text-transform: uppercase;
    }

    &-value {
      font-size: 4rem;
      text-align: center;
    }

    &-description {
      display: inline-block;
      width: 90%;
      font-size: 1.7rem;
      font-weight: 100;
      font-style: italic;
      text-align: center;
    }
  }
}

.contact {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(7, 1fr);
  gap: 8px;
  height: 900px;
  padding: 5em 2em;
  font-size: 1.8rem;
  background-color: c.$black;
  color: c.$gray;

  &__title {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    margin-bottom: 1em;
    font-size: 4rem;
    text-align: center;
  }

  &__address {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    display: flex;
    flex-direction: column;

    &-name {
      font-size: 2.5rem;
    }
  }

  &__email {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
    margin: 1em 0 0;

    .fas {
      margin-right: 1em;
    }

    a {
      color: c.$white;
      text-decoration: none;
      cursor: pointer;
    }
  }

  &__phones {
    grid-column: 1 / 2;
    grid-row: 4 / 5;
    font-size: 1.8rem;
    margin: 1em 0;

    & span {
      margin-right: 0.5em;
    }
  }

  &__identity {
    grid-column: 1 / 2;
    grid-row: 5 / 6;
    padding-bottom: 1em;

    & span {
      display: inline-block;
      margin-right: 0.5em;
    }
  }

  &__open {
    grid-column: 1 / 2;
    grid-row: 6 / 7;
    padding-bottom: 1em;

    &-text {
      color: c.$orange;
      text-align: center;
    }
  }

  &__map {
    grid-column: 1 / 2;
    grid-row: 7 / 8;
    height: 300px;
    width: 100%;
    margin: 0 auto;
  }
}

@import "media";
