@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  font-family: "Poppins", sans-serif;
  font-size: 1.6rem;
  width: 100%;
  background-color: #e5e5e5;
}

.menu {
  position: fixed;
  top: 0;
  right: 0;
  width: 0;
  height: 100%;
  display: flex;
  align-items: center;
  transition: width 0.3s;
}
.menu__list {
  list-style: none;
}
.menu__item {
  margin: 1.8em auto;
}
.menu__item a {
  margin-left: 2em;
  text-decoration: none;
  font-size: 2.4rem;
  color: #000;
  opacity: 0;
  transition: opacity 0.1s;
}
.menu__item a:hover {
  color: #fca311;
}
.menu.is-active {
  transition-delay: 0.1s;
  width: 20rem;
}
.menu.is-active a {
  opacity: 1;
}

.container {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: auto;
}
.container.is-active {
  right: 20rem;
}
.container.is-active .showcase__toggle .hamburger-inner,
.container.is-active .showcase__toggle .hamburger-inner::before,
.container.is-active .showcase__toggle .hamburger-inner::after {
  background-color: #000;
}

.showcase {
  position: relative;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  padding: 1em 1.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #000;
  color: #fff;
  z-index: 2;
}
.showcase__header {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: auto;
  padding: 0.2em 1.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
}
.showcase__header.out-home {
  background: rgba(0, 0, 0, 0.5);
}
.showcase__logo {
  color: #fca311;
  font-size: 2.5rem;
}
.showcase__toggle {
  position: relative;
}
.showcase__toggle .hamburger .hamburger-inner,
.showcase__toggle .hamburger .hamburger-inner::before,
.showcase__toggle .hamburger .hamburger-inner::after {
  background-color: #fca311;
  transition: background-color 0.3s;
}
.showcase__video {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.8;
}
.showcase__overlay {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
}
.showcase__text {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 20vh;
  height: 65vh;
  z-index: 10;
}
.showcase__text h1 {
  font-size: 4.5rem;
  font-weight: 800;
  line-height: 5rem;
}
.showcase__text p {
  display: inline-block;
  max-width: 50%;
  font-size: 2rem;
  font-weight: 400;
  line-height: 3rem;
}
.showcase__text a {
  display: inline-block;
  padding: 0.5em 1em;
  font-weight: 200;
  letter-spacing: 1px;
  text-decoration: none;
  text-align: center;
  border: 1px solid #fca311;
  border-radius: 5px;
  background-color: transparent;
  color: #fff;
  transition: background-color 0.3s, color 0.3s;
}
.showcase__text a:hover {
  background-color: #000;
  color: #fca311;
}

.wrapper {
  max-width: 1300px;
  margin: 0 auto;
}

.about,
.tools,
.offer,
.prices {
  position: relative;
  top: 0;
  right: 0;
  width: 100%;
  height: auto;
}
.about__title,
.tools__title,
.offer__title,
.prices__title {
  font-size: 4rem;
  padding: 3em 1em 1em;
  text-transform: uppercase;
  text-align: center;
}
.about__img img,
.tools__img img,
.offer__img img,
.prices__img img {
  margin: 0 0.5em 0 0.5em;
  width: 10em;
  float: left;
}
.about__text,
.tools__text,
.offer__text,
.prices__text {
  text-align: justify;
  margin: 0 0.5em;
  font-size: 1.8rem;
}

.tools__img img {
  float: right;
}

.offer {
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.offer h3 {
  padding-bottom: 4em;
  font-size: 2rem;
}
.offer__cards {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}
.offer__cards .card {
  background-color: transparent;
  width: 18em;
  height: 23em;
  perspective: 1000px;
  margin-bottom: 3em;
}
.offer__cards .card__inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}
.offer__cards .card:hover .card__inner {
  transform: rotateY(180deg);
}
.offer__cards .card__front, .offer__cards .card__back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border: 2px solid #fca311;
  border-radius: 1em;
  background-color: #e5e5e5;
  color: #000;
}
.offer__cards .card__back {
  transform: rotateY(180deg);
}
.offer__cards .card__img {
  padding: 0.1em;
  font-size: 7rem;
  color: #fca311;
}
.offer__cards .card__title {
  text-transform: uppercase;
  font-size: 3.2rem;
  color: #14213d;
  padding-bottom: 0.8em;
}
.offer__cards .card__list {
  list-style: none;
  padding: 0 1em 1em;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2;
  text-transform: uppercase;
}
.offer__cards .card__verte {
  font-weight: 200;
}

.prices {
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1em;
}
.prices__descriptions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2em;
}
.prices__text {
  margin-bottom: 1em;
}
.prices__pins {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
  width: 90%;
  margin-bottom: 2em;
}
.prices__pin {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 18em;
  height: 18em;
  margin-bottom: 2em;
  border: 1px solid #fca311;
  box-shadow: -5px 5px 10px #555;
}
.prices__pin-img {
  width: 3.5em;
}
.prices__pin-title {
  padding: 0 0.5rem;
  font-size: 2.6rem;
  text-align: center;
  text-transform: uppercase;
}
.prices__pin-value {
  font-size: 4rem;
  text-align: center;
}
.prices__pin-description {
  display: inline-block;
  width: 90%;
  font-size: 1.7rem;
  font-weight: 100;
  font-style: italic;
  text-align: center;
}

.contact {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(7, 1fr);
  gap: 8px;
  height: 900px;
  padding: 5em 2em;
  font-size: 1.8rem;
  background-color: #000;
  color: #e5e5e5;
}
.contact__title {
  grid-column: 1/2;
  grid-row: 1/2;
  margin-bottom: 1em;
  font-size: 4rem;
  text-align: center;
}
.contact__address {
  grid-column: 1/2;
  grid-row: 2/3;
  display: flex;
  flex-direction: column;
}
.contact__address-name {
  font-size: 2.5rem;
}
.contact__email {
  grid-column: 1/2;
  grid-row: 3/4;
  margin: 1em 0 0;
}
.contact__email .fas {
  margin-right: 1em;
}
.contact__email a {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
.contact__phones {
  grid-column: 1/2;
  grid-row: 4/5;
  font-size: 1.8rem;
  margin: 1em 0;
}
.contact__phones span {
  margin-right: 0.5em;
}
.contact__identity {
  grid-column: 1/2;
  grid-row: 5/6;
  padding-bottom: 1em;
}
.contact__identity span {
  display: inline-block;
  margin-right: 0.5em;
}
.contact__open {
  grid-column: 1/2;
  grid-row: 6/7;
  padding-bottom: 1em;
}
.contact__open-text {
  color: #fca311;
  text-align: center;
}
.contact__map {
  grid-column: 1/2;
  grid-row: 7/8;
  height: 300px;
  width: 100%;
  margin: 0 auto;
}

@media screen and (max-width: 359px) {
  .showcase h1 {
    font-size: 3.9rem;
  }

  .prices__pin {
    width: 16.5em;
    height: 16.5em;
  }
  .prices__pin-img {
    width: 3em;
  }
  .prices__pin-title {
    font-size: 2.5rem;
  }
  .prices__pin-value {
    font-size: 3.2rem;
  }
  .prices__pin-description {
    font-size: 1.4rem;
  }

  .contact__phones {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 300px) {
  .showcase h1 {
    font-size: 3.2rem;
  }

  .about__img img,
.tools__img img,
.offer__img img,
.prices__img img {
    width: 95%;
  }

  .offer__cards {
    width: 95%;
  }
  .offer__cards .card {
    width: 16em;
    height: 21em;
  }
  .offer__cards .card__img {
    font-size: 5rem;
  }
  .offer__cards .card__title {
    font-size: 2.8rem;
  }
  .offer__cards .card__list {
    font-size: 1.4rem;
  }

  .prices__pin {
    width: 14.5em;
    height: 14.5em;
  }

  .contact {
    height: 1000px;
  }
  .contact__address-name {
    font-size: 2rem;
  }
  .contact__phones {
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-template-rows: 1fr 1fr;
  }
  .contact__phones-text {
    grid-column: 1/2;
    grid-row: 1/3;
  }
  .contact__phones-first {
    grid-column: 2/3;
    grid-row: 1/2;
    font-size: 1.8rem;
  }
  .contact__phones-second {
    grid-column: 2/3;
    grid-row: 2/3;
    font-size: 1.8rem;
  }
}
@media screen and (max-height: 500px) {
  .menu__list {
    margin-top: 3.5em;
  }
  .menu__item {
    margin: 1.2em auto;
  }
  .menu__item a {
    font-size: 2.2rem;
  }
}
@media screen and (max-height: 321px) {
  .menu__list {
    margin-top: 2em;
  }
  .menu__item {
    margin: 0.5em auto;
  }
  .menu__item a {
    font-size: 2rem;
  }
}
@media screen and (max-height: 280px) {
  .menu__list {
    margin-top: 3em;
  }
  .menu__item {
    margin: 0.4em auto;
  }
  .menu__item a {
    font-size: 1.6rem;
  }
}
@media screen and (orientation: landscape) {
  .showcase__logo {
    font-size: 2rem;
  }
  .showcase h1 {
    font-size: 3rem;
    max-width: 100%;
  }
  .showcase p {
    font-size: 1.8rem;
    max-width: 100%;
  }

  .about__img img,
.tools__img img,
.offer__img img,
.prices__img img {
    width: 15em;
  }
  .about__text,
.tools__text,
.offer__text,
.prices__text {
    font-size: 1.5rem;
  }

  .offer h3 {
    font-size: 1.6rem;
  }

  .prices__pins {
    flex-direction: row;
  }
}
@media screen and (min-width: 640px) and (orientation: landscape) {
  .showcase__logo {
    font-size: 2.5rem;
  }
  .showcase h1 {
    font-size: 3.5rem;
  }
  .showcase p {
    font-size: 1.8rem;
  }

  .about__img img,
.tools__img img,
.offer__img img,
.prices__img img {
    width: 18em;
  }
  .about__text,
.tools__text,
.offer__text,
.prices__text {
    font-size: 1.5rem;
  }

  .offer h3 {
    font-size: 1.6rem;
  }
  .offer__cards {
    flex-direction: row;
  }

  .contact {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(6, 1fr);
    height: 800px;
  }
  .contact__title {
    grid-column: 1/3;
  }
  .contact__address {
    grid-row: 2/5;
    margin: 0;
  }
  .contact__email {
    grid-column: 2/3;
    grid-row: 2/3;
    margin: 0;
  }
  .contact__phones {
    grid-column: 2/3;
    grid-row: 3/4;
    margin: 0;
  }
  .contact__identity {
    grid-column: 2/3;
    grid-row: 4/5;
    padding-bottom: 0;
  }
  .contact__open {
    grid-column: 1/3;
    grid-row: 5/6;
    padding: 3em 0;
  }
  .contact__map {
    grid-column: 1/3;
    grid-row: 6/7;
  }
}
@media screen and (min-width: 811px) and (orientation: landscape) {
  .showcase h1 {
    font-size: 4rem;
  }
  .showcase p {
    font-size: 2rem;
  }

  .about__title,
.tools__title,
.offer__title,
.prices__title {
    font-size: 3.5rem;
    padding: 2em 1em 2em;
  }
  .about__img img,
.tools__img img,
.offer__img img,
.prices__img img {
    width: 20em;
  }
  .about__text,
.tools__text,
.offer__text,
.prices__text {
    font-size: 1.8rem;
  }

  .offer h3 {
    font-size: 1.6rem;
  }
  .offer__cards {
    flex-direction: row;
  }
}
@media screen and (min-width: 960px) and (orientation: landscape) {
  .showcase__logo {
    font-size: 4rem;
  }
  .showcase h1 {
    font-size: 8rem;
    line-height: 10rem;
    max-width: 80%;
  }
  .showcase p {
    font-size: 4rem;
    max-width: 100%;
  }
  .showcase a {
    font-size: 3rem;
  }

  .hamburger-box {
    width: 60px;
    height: 40px;
    display: inline-block;
    position: relative;
  }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 60px;
    height: 6px;
  }
  .hamburger-inner::before {
    top: -38px;
  }

  .about__title,
.tools__title,
.offer__title,
.prices__title {
    font-size: 4.5rem;
  }
  .about__img img,
.tools__img img,
.offer__img img,
.prices__img img {
    width: 30em;
  }
  .about__text,
.tools__text,
.offer__text,
.prices__text {
    font-size: 2.2rem;
  }

  .offer h3 {
    font-size: 2.2rem;
  }

  .tools__text {
    margin: 1em 1em 2.2em;
  }

  .contact {
    font-size: 2.2rem;
    height: 900px;
  }
  .contact__title {
    margin-bottom: 1em;
    font-size: 4.5rem;
  }
  .contact__address-name {
    font-size: 3rem;
  }
  .contact__email {
    margin: 0;
  }
  .contact__email .fas {
    margin-right: 1em;
  }
  .contact__phones {
    margin: 0;
  }
  .contact__phones span {
    margin-right: 0.5em;
  }
  .contact__identity {
    padding-bottom: 0;
  }
  .contact__identity span {
    margin-right: 0.5em;
  }
  .contact__open {
    padding-bottom: 1em;
  }
  .contact__map {
    height: 400px;
  }
}
@media screen and (min-width: 1330px) and (orientation: landscape) {
  .contact {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(5, 1fr);
    height: 500px;
  }
  .contact__title {
    grid-column: 1/3;
  }
  .contact__address {
    grid-column: 1/2;
    grid-row: 2/5;
    margin: 0;
  }
  .contact__email {
    grid-column: 2/3;
    grid-row: 2/3;
    margin: 0;
  }
  .contact__phones {
    grid-column: 2/3;
    grid-row: 3/4;
    margin: 0;
  }
  .contact__identity {
    grid-column: 2/3;
    grid-row: 4/5;
    padding-bottom: 0;
  }
  .contact__open {
    grid-column: 1/4;
    grid-row: 5/6;
    padding: 3em 0;
  }
  .contact__map {
    grid-column: 3/4;
    grid-row: 1/6;
    height: 250px;
  }
}
@media screen and (min-width: 736px) and (orientation: portrait) {
  .showcase h1 {
    font-size: 7rem;
    line-height: 9rem;
    max-width: 80%;
  }
  .showcase p {
    font-size: 3rem;
    line-height: 6rem;
    max-width: 100%;
  }
  .showcase a {
    font-size: 2.5rem;
  }

  .about__title,
.tools__title,
.offer__title,
.prices__title {
    font-size: 4rem;
    padding: 2em 1em 2em;
  }
  .about__img img,
.tools__img img,
.offer__img img,
.prices__img img {
    width: 20em;
  }
  .about__text,
.tools__text,
.offer__text,
.prices__text {
    font-size: 2.2rem;
  }

  .offer h3 {
    font-size: 2.2rem;
  }
  .offer__cards {
    flex-direction: row;
  }

  .prices__pins {
    flex-direction: row;
  }

  .contact {
    font-size: 2.4rem;
    height: 1100px;
  }
  .contact__title {
    font-size: 4rem;
  }
  .contact__address-name {
    font-size: 3rem;
  }
  .contact__phones {
    font-size: 2.4rem;
  }
  .contact__open-text {
    font-size: 2.2rem;
  }
  .contact__map {
    height: 400px;
    width: 95%;
  }
}
@media screen and (min-width: 1024px) and (orientation: portrait) {
  .showcase h1 {
    font-size: 9rem;
    line-height: 12rem;
  }
  .showcase p {
    font-size: 4rem;
    line-height: 6rem;
  }
  .showcase a {
    font-size: 3rem;
  }

  .about__title,
.tools__title,
.offer__title,
.prices__title {
    font-size: 5rem;
  }
  .about__img img,
.tools__img img,
.offer__img img,
.prices__img img {
    width: 30em;
  }
  .about__text,
.tools__text,
.offer__text,
.prices__text {
    font-size: 3rem;
  }

  .offer h3 {
    font-size: 3rem;
  }

  .contact {
    font-size: 3rem;
    height: 1400px;
  }
  .contact__title {
    font-size: 4.5rem;
  }
  .contact__address-name {
    font-size: 4rem;
  }
  .contact__phones {
    font-size: 3rem;
  }
  .contact__open-text {
    font-size: 2.8rem;
  }
  .contact__map {
    height: 500px;
  }
}